<template>
    <div>
        <v-main fluid class="mt-n6">
            <div>
                <v-form ref="form"
                        v-model="valid"
                        class="w-100">

                    <v-container v-show="GetWhatToShow == 'view'" class="w-100 pa-8">
                        <kview form="siparis"
                               :viewname="GetViewName"
                               @rowclicked="ViewClicked($event, 'view')"></kview>
                    </v-container>
                    <v-container v-show="GetWhatToShow != 'view'" fluid class="pa-8">
                        <v-btn text tile color="primary" @click="BackToView">
                            <v-icon left>mdi-arrow-left</v-icon>{{$t("AL_Back")}}
                        </v-btn>
                        <v-row>
                            <v-col class="pb-0 mb-0">
                                <dynoform header="Firmamız Siparişleri"
                                          @change="UpdateFormField($event, 'formdata')"
                                          @changeGrid="UpdateExtData($event, $event.tableName)"
                                          @click="FormButtonClicked($event)"
                                          :attributes="GetFormAttributes"
                                          @SearchInputChanged="SearchInputChanged($event, 'formdata')"
                                          @RowButtonClicked="RowButtonClicked($event)"
                                          :tabname="Math.random().toString()"></dynoform>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-dialog v-model="actionBarWait"
                          persistent>
                    <v-card color="transparent"
                            dark class="py-5">
                        <v-card-text class="text-center py-5">
                            <v-progress-circular indeterminate
                                                 :size="50"
                                                 color="white"></v-progress-circular>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
            <v-footer class="grey lighten-3 pa-3" inset app padless>
                <v-progress-circular v-if="actionBarWait" :size="20" :width="2" rounded indeterminate color="primary" class="my-2 mr-2"></v-progress-circular>
                <v-toolbar-title v-if="actionBarWait" class="primary--text overline">{{$t("IM_YourTransactionIsInProgress")}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn v-show="GetWhatToShow != 'view'" v-for="(actionInfo, index) in GetFormAction"
                       :loading="actionBarWait"
                       @click="ApprovedAction(actionInfo)"
                       depressed
                       class="mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize"
                       :class="index == GetFormAction.length - 1 ? 'primary' : 'secondary'"
                       :key="actionInfo.action">
                    {{ $t(actionInfo.label) }}
                </v-btn>
            </v-footer>

            <v-dialog v-model="showDetailDialog" class="p-3 mb-0" width="400" height="400">
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col class="pb-0 mb-0" style="height: 200px">
                                <v-col cols="12">
                                    <br />
                                    {{$t("TH_Details")}}
                                    <br />
                                    <v-textarea id="notlardetay"
                                                ref="notlardetay"
                                                v-model="detailDialogText"
                                                dense
                                                outlined
                                                hide-details
                                                color="primary"
                                                contenteditable="false"
                                                v-bind:placeholder="$t('TH_Details')"
                                                background-color="grey lighten-3"></v-textarea>
                                    <br />
                                </v-col>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="showDetailDialog = false"
                               depressed
                               class="mx-2 px-10 primary darken-1 white--text font-weight-medium text-capitalize secondary">
                            Kapat
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-main>
    </div>
</template>

<style>
    .grey-lighten-4 {
        background-color: #f5f5f5;
    }

    .v-dialog {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
</style>

<script>

    import Utilities from '@/Utilities.js'
    import Vue from 'vue';
    import Vuex from 'vuex';
    Vue.use(Vuex);

    var sal_kalem_detay = {
        tableName: 'sal_kalem_detay',
        columns: {
            hartipiad: {
                type: 'text',
                name: 'hartipiad',
                label: 'Category',
                edit: '',
                validateMin: 1,
            },
            ad: {
                type: 'text',
                name: 'ad',
                label: 'FL_ItemName',
                edit: '',
                validateMin: 1,
            },
            notlar: {
                type: 'text',
                name: 'notlar',
                label: 'FL_ShortDescription',
                edit: '',
                validateMin: 1,
            },
            talepmiktar: {
                type: 'text',
                name: 'talepmiktar',
                label: 'FL_OrderedAmount',
                edit: '',
                validateMin: 1,
            },
            alisfiyat: {
                type: 'money',
                name: 'alisfiyat',
                label: 'FL_UnitPrice',
                edit: '',
                validateMin: 1,
                align: 'right'
            },
            doviz: {
                type: 'text',
                name: 'doviz',
                label: 'FL_Currency',
                edit: '',
            },
            detaybutton: {
                type: 'button',
                label: 'TH_Details',
                name: 'detaybutton',
                edit: '01X92Q',
                width: 15,
            },
            notlardetay: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'notlardetay',
                width: 0
            },
            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            },
            id: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'id',
                width: 0
            },
        },
        attributes:
        {
            0:
            {
                id: { items: [], error: false, readonly: false, hidden: false },
                formid: { items: [], error: false, readonly: false, hidden: false },
                miktar: { items: [], error: false, readonly: false, hidden: false },
                paracinsi: { items: [], error: false, readonly: false, hidden: false },
                alisfiyat: { items: [], error: false, readonly: false, hidden: false },
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'sal_kalem_detay_siparis'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };

    var Siparis_formInfo = {
        tabs: [
            { order: 1, header: 'FN_Order' },
        ],
        groups: [
            {
                tab: 1,
                order: 1,
                header: 'TH_FormInformation'
            },
            {
                tab: 1,
                order: 2,
                header: 'FL_DeliveryInformation'
            },
            {
                tab: 1,
                order: 3,
                header: 'TH_ItemInformation'
            },
            {
                tab: 1,
                order: 4,
                header: 'FL_AdditionalFiles'
            },
            {
                tab: 1,
                order: 5,
                header: 'FL_Notes'
            },
        ],

        Columns: {
            id: {
                type: 'int',
                group: 1,
                sira: 10,
                name: 'id',
                label: 'FL_FormNo',
                edit: '',
                col: '6',
            },

            konu: {
                type: 'text',
                group: 1,
                sira: 15,
                name: 'konu',
                label: 'FL_Subject',
                edit: '',
                col: '6',
            },

            bastarih: {
                type: 'date',
                name: 'bastarih',
                label: 'FL_StartDate',
                edit: '',
                validateMin: 1,
                group: 1,
                sira: 17,
                col: '6',
            },
            bittarih: {
                type: 'date',
                name: 'bittarih',
                label: 'FL_EndDate',
                edit: '',
                validateMin: 1,
                group: 1,
                sira: 20,
                col: '6',
            },
            kabuledildi: {
                type: 'select',
                group: 1,
                sira: 21,
                name: 'kabuledildi',
                label: 'FL_OrderAccepted',
                edit: '',
                col: 6,
                options:[]
            },
            teslimtarihi: {
                type: 'date',
                group: 1,
                sira: 25,
                name: 'teslimtarihi',
                label: 'FL_DeliveryDate',
                edit: '',
                col: 6,
                options: []
            },
            miktarsiz: {
                type: 'hidden',
                name: 'miktarsiz',
            },

            teslimatadres: {
                type: 'textarea',
                group: 2,
                sira: 30,
                name: 'teslimatadres',
                label: 'FL_AddressToDeliver',
                edit: '',
                col: '12',
            },

            aciklama: {
                type: 'textarea',
                group: 2,
                sira: 35,
                name: 'aciklama',
                label: 'FL_Comment',
                edit: '',
                col: '12',
            },

            sal_kalem_detay: {
                type: 'grid',
                group: 3,
                sira: 10,
                name: 'sal_kalem_detay',
                label: 'FL_OrderItems',
                edit: '',
                col: '12',
                showselect: false,
                buttons: [],
                dontCompare: true,
            },
            ekdosya1: {
                type: 'file',
                group: 4,
                sira: 0,
                name: 'ekdosya1',
                col: '4',
                edit: '',
                label: 'Dosya 1'
            },
            ekdosya2: {
                type: 'file',
                group: 4,
                sira: 5,
                name: 'ekdosya2',
                col: '4',
                edit: '',
                label: 'Dosya 2'
            },
            ekdosya3: {
                type: 'file',
                group: 4,
                sira: 10,
                name: 'ekdosya3',
                col: '4',
                edit: '',
                label: 'Dosya 3'
            },
            ekdosya4: {
                type: 'file',
                group: 4,
                sira: 15,
                name: 'ekdosya4',
                col: '4',
                edit: '',
                label: 'Dosya 4'
            },
            ekdosya5: {
                type: 'file',
                group: 4,
                sira: 20,
                name: 'ekdosya5',
                col: '4',
                edit: '',
                label: 'Dosya 5'
            },
            oonaynot: {
                type: 'history',
                group: 5,
                sira: 25,
                name: 'oonaynot',
                col: '12',
                edit: '9Q',
                label: ''
            },
        },
        form: 'siparis',
        formid: 0,
        viewName: 'benim',
        viewparameter: '',
        ignoreActions: true,
        files: ['ekdosya1', 'ekdosya2', 'ekdosya3', 'ekdosya4', 'ekdosya5'],
        extdata: [sal_kalem_detay],
        actions: [],
        parameters: [],
        attributes: {}
    };

    import { mapGetters } from 'vuex';
    import dynoform from '@/components/DynoForm.vue';
    import grid from '@/components/grid.vue';
    import leftmenu from '@/components/leftmenu.vue';
    import kview from '@/components/view.vue';
    import axios from 'axios';
    import _ from 'lodash';
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import store from '@/store';
    import moment from 'moment';

    import showdialog from '@/components/ShowDialog.vue';
    Vue.component('showdialog', showdialog);

    import { acosh } from 'core-js/core/number';

    export default {
        name: 'siparis',
        mixins: [FormMixIn],
        components: {
            dynoform,
            leftmenu,
            kview,
        },
        props: {
            //form: String,
            formid: [Number, String],
            view: String,
            drawer: { type: Boolean, default: true },
            message: Object
        },
        data: function ()
        {
            return {
                valid: false,
                showDamgaEkFile: '0',
                messages: { messageType: '', messageBody: '', infoBody: '', viewType: '' },
                viewName: '',
                username: '',
                form: '',
                formname: 'siparis',
                openallpanel: [0],
                currentform: 'siparis',
                aciklama: '',
                errors: [],
                showDetailDialog: false,
                detailDialogText: ''
            };
        },
        methods: {
            FormLoaded: function ()
            {
                var currstate = this.GetState;
                var isAccepted = this.GetFormField('kabuledildi') == "1";
                if ("9Q".indexOf(currstate) != -1 && !this.actionBarWait && !isAccepted)
                {
                    this.$store.commit('SetFormActions', [{ label: 'AL_Inform', action: 'PortalBilgi', type: 'external' }, { label: 'AL_AcceptOrder', action: 'PortalGonderildi', type: 'external' }]);
                }
                else if ("Z".indexOf(currstate) == -1)
                {
                    this.$store.commit('SetFormActions', [{ label: 'AL_Inform', action: 'PortalBilgi', type: 'external' }]);
                }
                else
                {
                    this.$store.commit('SetFormActions', []);
                }

                this.SetExtDataColumnAttribute("sal_kalem_detay", "talepmiktar", "hide", (Number(this.GetFormField('portaldeGoster')) == 2) ? currstate : "");
            },

            ValidateForm: function (action) {
                this.ClearFieldErrors();
                this.errors = [];
                var result = "";

                var formdata = this.GetFormData;

                if (action.action == "PortalBilgi") {
                    var err;
                    if (!formdata.oonaynot) {
                        err = this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t('FL_Note') });
                        this.errors.push(err);
                    }
                }
                if (this.errors.length > 0) {
                    result = this.errors;
                }

                return result;
            },

            Selected: function ()
            {
                return;
            },

            NeedApprove: function (action)
            {
                var res;
                switch (action)
                {
                    case 'PortalGonderildi':
                        res = this.$root.$i18n.t('DM_OrderSentTransactionConfirm');
                        break;
                    default:
                        res = '';
                }
                return res;
            },
            FormButtonClicked(col)
            {
                var name = col.name;
            },

            RowClicked: function (rowdata, table)
            {
                return;
            },

            RowButtonClicked: function (payload, tableName)
            {
                console.log("Row Clicked in siparis payload:", payload);
                if (payload.button.name == "detaybutton") {
                    this.showDetailDialog = true;
                    this.detailDialogText = payload.item.notlardetay;
                }
            },

            ChangeAttribute: function (attribute, tableName)
            {
                var payload = { ...attribute, tableName };
                Utilities.log("Grid Emit changeAttribute", payload);
                this.$store.commit('SetFieldAttributes', payload);
            },
        },
        mounted: function ()
        {
            this.openallpanel = [0];
            this.$store.commit('updateFormInfo', Siparis_formInfo);
            var form = Siparis_formInfo.form;
            var whatToShow = this.$route.name.replace(form, '');
            this.$store.commit('SetWhatToShow', { show: whatToShow });
            if (whatToShow == 'form') {
                var params = this.$route.params;
                if (params != undefined && params.id != undefined && Number(params.id) > 0) {
                    var formid = Number(params.id);

                    if (formid != undefined && formid != 0) {
                        this.$store.commit('SetFormAndFormId', {
                            form: form,
                            formid: formid
                        });
                        this.LoadFormData(formid);
                    }
                }
            }
            else if (whatToShow == 'yeni') {
                this.$store.commit('SetFormAndFormId', {
                    form: form,
                    formid: 0
                });
                this.LoadFormData(0);
            } else {
                this.$store.commit('SetWhatToShow', { show: whatToShow });
            }
            var self = this;
            this.$store.dispatch('GetUserInfo').then(userInfo => {
                self.username = userInfo.data.AdSoyad;
            });

            Siparis_formInfo.Columns.kabuledildi.options = [
                { text: this.$root.$i18n.t('OP_Yes'), value: '1' },
                { text: this.$root.$i18n.t('OP_No'), value: '0' },
            ];
        },
        computed: {
            FormAttributes: function ()
            {
                return this.GetFormAttributes;
            },
            UserName()
            {
                return this.GetUserName;
            },
            FormLoadingStatus: {
                get: function ()
                {
                    return this.GetFormLoadingStatus;
                }
            },
            GetState: function ()
            {
                return this.$store.getters.GetFormState;
            },
        },
        watch: {
            FormLoadingStatus: {
                handler: function (val, oldVal)
                {
                    console.log('FormLoadingStatus changed');
                },
                deep: true
            },


            $route: function (nval, oval)
            {
                console.log('WATCH Route :', nval, ' form:', this.formname);
                console.log('WATCH Route this:', this.$route);
                var form = Siparis_formInfo.form;
                var whatToShow = this.$route.name.replace(form, '');
                this.$store.commit('SetWhatToShow', { show: whatToShow });
                if (whatToShow == 'form') {
                    var params = this.$route.params;
                    if (params != undefined && params.id != undefined && Number(params.id) > 0) {
                        var formid = Number(params.id);

                        if (formid != undefined && formid != 0) {
                            this.$store.commit('SetFormAndFormId', {
                                form: form,
                                formid: formid
                            });
                            this.LoadFormData(formid);
                        }
                    }
                }
                else if (whatToShow == 'yeni') {
                    this.$store.commit('SetFormAndFormId', {
                        form: form,
                        formid: 0
                    });
                    this.LoadFormData(0);
                } else {
                    this.$store.commit('SetWhatToShow', { show: whatToShow });
                }
            },

        }
    };
</script>
